html {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: white;
}


*,
*::before,
*::after {
  box-sizing: inherit;
}



body {
  font-family: "Gabarito", serif;
  font-optical-sizing: auto;
  background-color: white;
  box-sizing: border-box ;
  -ms-box-sizing: border-box !important;
  color: #111;
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
}




.button:focus {
  outline: none;
}


a {
  text-decoration: none;
  color: #767676;
}

p {
  margin: 0;
}


button,
input[type="submit"],
input[type="reset"] {

  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}


.inputfile {
  width: 100px;
  height: 100px;
  opacity: 10;
  border-color: #EA754D;
  display: block;
}



.header,
.header2 {
  width: 100%;
  height: 64px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #dbdcdf;
}

.header2 {
  justify-self: center; /* This property is specific to header2 */
}





.header div {
  display: none;
}

select, input {
  font-family: "Gabarito", serif;
  font-optical-sizing: auto;
}

.select {
  height: 30px;
  border: none;
  color: #111;
  border-bottom: 1.7px solid #111;
  text-decoration: none;
  outline: none;
  background-color: none;
  background: none;
  font-size: 13px;
  width: 100%;
}

.side-header {
  display: none;
}


.logo {
  height: 36px;
  width: auto;
}

.bottom-logo {
  width: auto;
  height: 50px;
}

.img-container {
  width: 100%;
  height: 164px;
  object-fit: cover;
  object-position: center;
}

.img-product-container {
  width: 88px;
  height: 88px;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
}

.menu-filter {
  width: 100%;
  height: 164px;
  background: rgba(17, 17, 17, 0.2);
}

.menu-title {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  display: flex !important;
  font-weight: normal;
  font-size: 25px;
  padding: 0 15px;
}

.loading-menu{
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-title-text{
  margin-right: 10px !important;
  font-size: 28px;
}

.menu-title-container {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: space-between;
  width: 100%;
}

.menu-title-text-container {
  display: flex;
  width: 100%;
  align-items: center;
}

.modal-label-container{
  max-width: 70% !important;
  align-items: flex-end !important;
}

.modal-label{
  width: 18px;
  height: 18px;
  margin-left: 5px;
}

.label-title {
  width: 14px;
  height: 14px;
  margin-left: 5px;
}


.menu-review-header {
  padding: 8px 10px !important;
  font-size: 12px !important; 
}

.menu-links {
  display: flex;
  margin-top: 6px;

}

.link-social-container {
  display: flex;
}

.link-social {
  margin-left: 20px;
  width: 19px;
  height: 19px;
}

  .insta {
  width: 20px;
  height: 20px;
}

.tiktok{
  width: 21px;
  height: 21px;
}

.sub-menu-container {
  font-size: 15px;
  color: #717171;
  margin-bottom: 35px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 15px;
  margin-top: 10px;
}

.sub-menu-review {
  text-decoration: underline;
}

.sub-menu-title{
  margin-top: 0px;
  margin-right: 10px;
}


.sub-menu-title-span {
  margin-left: 10px;
}

.nav {
  display: flex !important;
  overflow-x: auto;
  position: sticky;
  top: 0;
  background-color: white;
  border-bottom: solid 1px #dbdcdf;
}

.menu-container {
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  border-bottom: solid 3px transparent;
}

.legal-container {
  padding: 60px 15px 0 15px!important;
}

.menu-text {
  display: flex !important;
  width: '100%';
  padding-bottom: 15px;
  padding-top: 15px;
  white-space: nowrap;
}

.category-title {
  display: flex !important;
  width: 100%;
  font-size: 22px;
  font-weight: normal;
}

.product {
  display: flex !important;
  width: 100%;
  justify-content: space-between;
  margin-top: 23px;
  align-content: center;
}

.product-text-container {
  max-width: 70%;
  font-size: 16px;
}

.noMWidth{
  max-width: 95%;
}

.product-price-text {
  margin-top: 6px;
  font-size: 14px;
}

.more-option-price {
 margin-top: 1px;
}

.allergen {
  width: auto;
  padding: 2px 6px;
  background-color: #717171;
  color: white;
  border-radius: 5px;
  font-size: 12px;
  margin-left: 5px;
}

.modal-allergen {
margin:20px 20px 0;
display: inline-block;
padding: 6px 6px;
font-size: 12px;
}

.product-desc-text {
  margin-top: 4px;
  color: #717171;
  font-size: 15px;
}

.product-wrapper {
  padding-bottom: 30px;
}


.product-container {
  padding-top: 60px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}


.product-modal-container {
  background-color: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 100vh;
  z-index: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  overflow-y: hidden;
}

.product-modal-img {
  width: 100%;
  height: 180px;
  background-position: center;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  align-items: center;
  align-content: center;
  object-fit: cover;
  object-position: center;
}

.modal-img-option {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.hide-img-modal {
  height: 0;
}

.product-modal-content {
  width: 100%;
  height: 80%;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  background-color: white;
  bottom: 0;
  position: fixed;
}

 .modal-options {
  height: 95%;
}


.modal-filter {
  width: 100%;
  height: 100%;
  background: rgba(17, 17, 17, 0.2);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.modal-text-container {
  padding: 20px 20px 0 20px;
  width: 100%;
}

.modal-text-header {
  font-size: 23px;
  max-width: 80%;
}

.modal-text-sub-header {
  font-size: 19px;
  max-width: 70%;
  margin-top: 30px;
}


.modal-option {
    width: 100%;
    margin-top: 20px;
  }


.modal-text-option {
display: flex;  
width: 100%;
  margin-top: 20px;
  justify-content: space-between;
}

.modal-max-width{
max-width: 75%;
}


.modal-text-price {
  font-size: 16px;
}

.modal-text-description {
  font-size: 15px;
  color: #717171;
  margin-top: 20px;
  line-height: 1.4;
}

.modal-header-container {
  justify-content: space-between;
  display: flex !important;
  align-items: center;
}

.modal-close-container {
  position: fixed;
  bottom: 0;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 30px;
  width: 100%;
  color: white;
}

.modal-close-button {
  width: 100%;
  height: 40px;
  justify-content: center;
  align-content: center;
  align-items: center;
  display: flex !important;
  border-radius: 100px;
  color: #111;
  background-color: white;
  border: solid 1px #111;;
}

.review-wrapper {
  padding: 50px 15px 35px 15px;
  width: 100%;
}

.bottom-logo-container {
  display: flex;
  width: 100%;
  justify-content: center;
 margin-top: 0px;
  margin-bottom: 0px;
}

.review-title {
  font-size: inherit;
  text-align: center;
  margin-top: 23px;
}

.review-text {
  color: #717171;
  margin-top: 10px;
  text-align: center;
}

.review-icons {
  font-size: 22px;
  display: flex;
  margin-top: 15px;
  justify-content: space-evenly;
  padding: 0 30%;
  color: #717171;

}

.no-review {
  padding: 150px 0 40px 0;
}



.wrapper-container-login {
  width: 100vw;
  display: flex;
  justify-content: center;
}

.wrapper-login {
  max-width: 450px;
  width: 100%;
  height: 100vh;
  padding: 40px;
  position: fixed;
}



.logo-container {
  margin-top: 80px;
  margin-bottom: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.logo-login {
  height: 60px;
  width: auto;
  align-self: center;
}


.header-login {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.welcome {
  font-size: 17px;
  font-weight: bold;
}


.text {
  text-align: center;
}



.button-container {
  display: flex;
  margin-top: 40px;
  margin-bottom: 80px;
  justify-content: center;
}

.google-button {
  width: 100%;
  height: 45px;
  border-radius: 100px;
  border: 1px solid;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 0 10px;
}



.google {
  width: 25px;
  height: 25px;
}








.wrapper-container {
  width: 100%;
  height: calc(100vh - 64px);
  display: flex;
  justify-content: center;
  padding-top: 150px;
  padding-right: 14%;
  padding-left: 14%;
  margin-bottom: 100px;
  height: auto;
}

.button {
  box-sizing: border-box;
  background-color: #4285f4;
  padding: 10px 20px;
  border-radius: 4px;
  color: white;
  float: right;
  font-weight: bold;
  font-size: 15px;
}

.save {
  min-width: 120px;
}

.more-option {
  background-color: white;
  border-radius: 90px;
  color: #111;
  border: solid 1px #111;
  font-weight: normal;
}

.wrapper-content {
  height: auto;
  width: 100%;
}

.not-found {
  width: 100%;
  display: flex;
  margin-top: 100px;
  justify-content: center;
  color: #717171;
}




.title-wrapper {
  margin-bottom: 30px;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  align-items: center;
}

.title-back {
  display: flex;
  align-items: center;
}

.back {
  margin-right: 15px;
  color: #717171;
  cursor: pointer;
}



.grey {
  color: #717171;
  font-weight: normal;
  margin-top: 5px;
  font-size: 14px;
}




.wrapper {
  width: 100%;
  display: block;
  height: auto;
  background-color: white;
  border-radius: 4px;
  padding: 0px 30px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.card-item:not(:last-child) {
  border-bottom: solid 1px #dbdcdf;
}

.border {
  border-bottom: solid 1px #dbdcdf;
}

.group-category {
  color: #717171;
  padding-top: 15px;
}


.card-item {
  width: 100%;
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.restaurant{
  padding: 20px 30px;
}

.category-product {
  color: #717171;
  width: 15%;
  display: flex;
}

.title-wrap {
  display: flex;
  align-items: center;
}


.profile {
  width: 70px;
  height: 70px;
  border-radius: 3px;
  margin-right: 20px;
  object-fit: cover;
  object-position: center;
}

.bg-grey {
  visibility: hidden;
  min-width: 70px;
}

.title {
  font-weight: bold;
}

.number {
  font-size: 23px;
  width: 50px;
 color: #717171;

}

.sub-title {
  color: #717171;
  margin-top: 5px;
}


.max-product {
  width: 30%;
}

.tool {
  font-size: 20px;
  color: #717171;
  cursor: pointer;
}

.link {
  font-weight: bold;
  color: #4285f4;
  cursor: pointer;
}

.link2 {
  font-weight: bold;
  color: #717171;
  cursor: pointer;
}

.desc {
  width: 30%;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}



.wrapper-menu {
  padding: 0;
}

.wrapper-form {
  width: 100%;
  display: block;
  height: auto;
  background-color: white;
  border-radius: 4px;
  padding: 30px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.input-container {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}

.option-container {
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


label {
  font-weight: normal;
  font-size: 16px;
}

input,
select {
  height: 30px;
  width: 60%;
  border: none;
  border-bottom: 1px solid #717171;
  text-decoration: none;
  outline: none;
  background-color: transparent;
  font-size: 16px;
  color: inherit;
  border-radius: 0;
}

.input-option-name{
  width: 60%;
}

.input-option-price{
  width: 15%;
}

.input-social{
  width: 30%;
}

.checkbox-container {
  display: flex;
  font-size: 12px;
  width: 60%;
  flex-wrap: wrap;
}


.checkbox-container input {
  height: 14px;
  width: 14px;
}

.checkbox-container label {
  font-weight: normal;
  font-size: 14px;
  margin-right: 15px;

}

input:focus {
  border-bottom: 2px solid #111;

}

.photo-container {
  width: 60%;
}



.photo-container .inputfile {
  width: 100px;
  height: 100px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  display: block;
}




.photo-container .photo {
  width: 110px;
  height: 110px;
  background-color: #717171;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 25px;
}



.photo-container .walpaper {
  width: 100%;
  height: 120px;
}



.input-container:not(:first-child) {
  margin-top: 10px;
}

.validation-container {
  width: 100%;
  align-items: center;
  padding-top: 30px;
  height: 80px;
}


.modify-translation-container {
  display: flex;
  justify-content: right;
  width: 100%;
  margin-bottom: 30px;
}

.modify-translation {
  width: 60%;
  float: right;
}


.delete {
  color: red;
  font-weight: bold;
  float: right;
  margin-right: 30px;
  margin-top: 8px;
  cursor: pointer;
}

.info {
  color: #111;
  font-weight: normal;
}

.wrapper-modify-opt {
  margin: 10px 0;
  padding: 15px 0 ;
}

.modify-opt-header {
  font-size: 16px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.modify-opt-header span {
 text-decoration: underline;
 cursor: pointer;
}



.modify-opt-text {
  color: #717171;
}

.legal {
  text-decoration: underline;
}









@media screen and (max-width: 767px) {
  .wrapper-container{
    padding: 50px 15px 0;
  }

  .wrapper{
    box-shadow: none;
    padding: 0;
  }

   .wrapper-form{
    box-shadow: none;
    padding: 0;
  }

  .desc{
    display: none;
  }

  .sub-title {
    max-width: 90%;
  }

  .sub-title-price {
    max-width: 100%;
  }

  .grey {
    max-width: 80%;
  }

  .title-back span {
    display: none;
  }

  label {
    margin-right: 15px;
    width: 40%;
  }


  .restaurant{
    padding: 20px 0;
  }

  .max-product {
    width: 60%;
  }

  .input-container {
    height: auto;
  }
  .title-wrapper {
    margin-bottom: 15px;
  }
}








@media screen and (min-width: 767px) {

  .mobile-user {
    display: none;
  }

  .wrapper-container-login {
    background: #111;
    height: 100vh;

    .wrapper-login {
      max-height: 544px;
      background-color: white;
      margin-top: 100px;
      border-radius: 10px;
      border: 1px solid;
      max-width: 400px;

    }
  }

  .menu-wrapper {
    justify-content: center;
    display: flex !important;
    width: 100%;
    background-color: black;
  }


  .menu-container2 {
    max-width: 450px;
    background-color: white;
  }

  .product-modal-container {
    justify-content: center;
    display: flex !important;
  }

  .product-modal-content {
    max-width: 450px;
  }

  .modal-close-container {
    max-width: 450px;

  }


  .header {
    padding: 0px 35px;
    justify-content: space-between;
    border-bottom: solid 1px #dbdcdf;
    position: fixed;
    z-index: 100;
    background-color: white;
    top: 0;
  }

  .header div {
    display: flex !important;
    cursor: pointer;
    align-items: center;
  }

  .header span {
    margin-right: 40px;
  }

  .header img {
    cursor: pointer;
    object-fit: cover;
    object-position: center;
    border-radius: 100px;
  }

  .logo {
    border-radius: 0 !important;
  }

  .userlogo {
    border-radius: 100px;
    width: 36px;
    height: 36px;
  }


  img {
    cursor: pointer;
  }

  .photo-container .walpaper {
    width: 250px;
    height: 120px;
  }

  .modify-translation {
    width: 60%;
  }

  .legal-container {
    padding: 13% 9% 4% 7%!important;
  }


}